<template>
  <div>
    <header-slot />

    <commissions tab="4"/>

  </div>
</template>

<script>
import Commissions from "@/views/commons/components/commissions/CommissionsComponent.vue"
import { mapMutations } from "vuex";

export default {
  components: {
    "commissions": Commissions,
  },
};
</script>

<style>
</style>